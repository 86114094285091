<template>
  <div class="mb-3 card">
    <div>
      <v-tabs v-model="tab">
        <v-tab key="list">
          {{ $t('erp.lang_stornogruende') }}
        </v-tab>
        <v-tab key="add">{{ $t('erp.lang_addStornogrund') }}</v-tab>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item key="list">
            <!-- Table -->
            <Datatable v-show="showTable" ref="voidReasonDatatable"
                       :api-endpoint="ENDPOINTS.DATATABLES.ERP.VOIDREASONS"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       :permissionDelete="this.$store.getters['permissions/checkPermission']('voidReasons')"
                       :permissionEdit="this.$store.getters['permissions/checkPermission']('voidReasons')"
                       excel-file-name="Allergene"
                       show-delete-buttons
                       show-edit-buttons
                       @deleteEntries="entriesDelete"
                       @deleteEntry="entryDelete"
                       @editEntry="entryEdit"
            />

            <v-container v-if="showUpdate" fluid>
              <v-row>
                <v-col cols="12">
                  <!-- Table: Click -->
                  <div>
                    <v-row>
                      <v-col md="5" sm="6">
                        <v-text-field v-model="voidReason"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :label="$t('erp.lang_stornoGrund')"
                                      autocomplete="off"
                                      outlined
                                      required
                                      @focus="showTouchKeyboard"
                        ></v-text-field>
                      </v-col>

                      <v-col md="5" sm="6">
                        <v-text-field v-model="voidReasonPosition"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :label="$t('generic.lang_position')"
                                      autocomplete="off"
                                      outlined
                                      required
                                      @focus="showTouchKeyboard"
                        ></v-text-field>
                      </v-col>
                      <v-col md="5" sm="6">
                        <div class="widget-content text-left pa-0">
                          <span class="font-weight-medium">{{ $t('erp.lang_warecreate_color') }}:</span>
                          <swatches
                              v-model="color" inline
                              background-color="transparent"
                          ></swatches>
                        </div>
                      </v-col>
                      <v-col md="5" sm="6">
                        <v-text-field v-model="konto"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :label="$t('accounting.langAccountingAccount')"
                                      autocomplete="off"
                                      outlined
                                      required
                                      @focus="showTouchKeyboard"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-radio-group
                            v-model="typeOfCancelation"
                            row
                        >
                          <v-radio
                              :label="$t('erp.lang_stornoGrund')"
                              value="1"
                          ></v-radio>
                          <v-radio
                              :label="$t('erp.lang_shrinkBreakage')"
                              value="2"
                          ></v-radio>

                        </v-radio-group>

                      </v-col><v-col cols="6">
                      <v-switch :label="$t('erp.lang_refillAfterVoid')" v-model="readdToStorage"/>
                    </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="text-right">
                        <v-btn color="error" text @click="resetData">{{
                            $t('generic.lang_prev')
                          }}
                        </v-btn>
                        <v-btn color="error" @click="deleteData">{{
                            $t('generic.lang_delete')
                          }}
                        </v-btn>
                        <v-btn :disabled="voidReason.length < 1" color="success"
                               @click="updateData">{{ $t('generic.lang_edit') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item key="add">
            <v-container fluid>
              <v-row>
                <v-col md="5" sm="6">
                  <v-text-field v-model="voidReason"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('erp.lang_stornoGrund')"
                                autocomplete="off"
                                outlined
                                required
                                @focus="showTouchKeyboard"
                  ></v-text-field>
                </v-col>

                <v-col md="5" sm="6">
                  <v-text-field v-model="voidReasonPosition"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('generic.lang_position')"
                                autocomplete="off"
                                outlined
                                required
                                @focus="showTouchKeyboard"
                  ></v-text-field>
                </v-col>
                <v-col md="5" sm="6">
                  <div class="widget-content text-left pa-0">
                    <span class="font-weight-medium">{{ $t('erp.lang_warecreate_color') }}:</span>
                    <swatches
                        v-model="color" inline
                        background-color="transparent"
                    ></swatches>
                  </div>
                </v-col>
                <v-col md="5" sm="6">
                  <v-text-field v-model="konto"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="$t('accounting.langAccountingAccount')"
                                autocomplete="off"
                                outlined
                                required
                                @focus="showTouchKeyboard"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-radio-group
                      v-model="typeOfCancelation"
                      row
                  >
                    <v-radio
                        :label="$t('erp.lang_stornoGrund')"
                        value="1"
                    ></v-radio>
                    <v-radio
                        :label="$t('erp.lang_shrinkBreakage')"
                        value="2"
                    ></v-radio>

                  </v-radio-group>

                </v-col><v-col cols="6">
                <v-switch :label="$t('erp.lang_refillAfterVoid')" v-model="readdToStorage"/>
              </v-col>
              </v-row>

              <v-row>
                <v-col class="text-right">
                  <v-btn color="error" text @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                  <v-btn :disabled="voidReason.length < 1" color="success" @click="addData">{{
                      $t('generic.lang_add')
                    }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from '../../../config'
import {Events} from "../../../plugins/events";
import Datatable from "../../datatable/Datatable";
import Swatches from 'vue-swatches';
import mixin from "../../../mixins/KeyboardMixIns";
import {i18n} from "@/main";

export default {
  components: {
    Datatable, Swatches
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,

      id: null,
      text: "",
      voidReasonPosition: "",
      voidReason: "",
      allergenAdd: "",
      konto:null,
      typeOfCancelation:"1",
      readdToStorage:true,
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      color: null,
    }
  },
  computed: {
    // --- Datatable ---
    datatableHeaders() {
      return [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: true
        },
        {text: this.$t('erp.lang_stornoGrund'), value: 'name', sort: "asc"},
        {text: this.$t('generic.lang_position'), value: 'position'},
        {text: this.$t('erp.lang_warecreate_color'), value: 'color', hide: true,},
      ];
    },
    excelColumns() {
      return [
        {
          label: "ID",
          field: "id",
        },
        {
          label: this.$t('erp.lang_stornoGrund'),
          field: "name",
        },
        {
          label: this.$t('generic.lang_position'),
          field: "position",
        },
      ];
    },
  },

  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },

  methods: {
    addData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ERP.VOIDREASONS.CREATE, {
        addVoidReasonName: this.voidReason,
        addVoidReasonPosition: this.voidReasonPosition,
        konto: this.konto,
        color: this.color,
        readdToStorage: this.readdToStorage,
        typeOfCancelation: parseInt(this.typeOfCancelation),
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_actionSuccessful'),
            color: "success"
          });
          self.allergenAdd = "";
          self.showList();
          self.$refs.voidReasonDatatable.getDataFromApi();
          self.$refs.voidReasonDatatable.resetSelectedRows();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    createNew: function () {
      this.resetData();
      this.showCreate = true;
      this.tab = 1;
      this.showTable = false;
    },
    updateData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ERP.VOIDREASONS.UPDATE, {
        editVoidReasonID: this.id,
        editVoidReasonName: this.voidReason,
        editVoidReasonPosition: this.voidReasonPosition,
        konto: this.konto,
        color: this.color,
        readdToStorage: this.readdToStorage,
        typeOfCancelation: parseInt(this.typeOfCancelation),
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          self.$refs.voidReasonDatatable.getDataFromApi();
          self.$refs.voidReasonDatatable.resetSelectedRows();
          self.resetData();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('erp.lang_deleteStornogrundTopic'),
        text: this.$t('erp.lang_deleteStornogrundText'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.ERP.VOIDREASONS.DELETE, {
            editVoidReasonID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_actionSuccessful'),
                color: "success"
              });

              self.resetData();
              self.$refs.voidReasonDatatable.getDataFromApi();
              self.$refs.voidReasonDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
      this.color = null;
      this.voidReasonPosition = "";
      this.voidReason = "";
      this.konto = "";
      this.readdToStorage = true;
      this.typeOfCancelation = 1;
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },
    entryEdit(entry) {

      let self = this;

      self.axios.post(ENDPOINTS.ERP.VOIDREASONS.GET, {
        editVoidReasonID: entry.id
      }).then((res) => {
        if (res.status === 200) {
          self.id = entry.id;
          self.voidReasonPosition = res.data.formfillData.textFields.editVoidReasonPosition;
          self.voidReason = res.data.formfillData.textFields.editVoidReasonName;
          self.color = res.data.formfillData.textFields.color;
          self.konto = res.data.formfillData.textFields.konto;
          self.typeOfCancelation = res.data.formfillData.textFields.typeOfCancelation.toString();
          self.readdToStorage = res.data.formfillData.textFields.readdToStorage;
          self.showTable = false;
          self.showUpdate = true;

        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });


    },
    entryDelete(entry) {
      this.id = entry.id;
      this.deleteData();
    },
    entriesDelete(entries) {
      this.deleteData(entries);
    }
  },
}
</script>